<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center">
      <h1 class="mb-5">Клубный игрок</h1>
    </div>

    <div style="margin-bottom: 20px">
      <router-link :to="'/club_player/clients'">
        <span class="d-block font-weight-semibold">Список участников</span>
      </router-link>
    </div>

    <v-card>
      <v-data-table
        mobile-breakpoint="100"
        :headers="headers"
        :items="gift_list"
        item-key="request_pk"
        :loading="loading"
        class="elevation-1"
        loading-text="Загрузка данных"
      >
        <template #[`item.request_pk`]="{ item }">
          <div class="d-flex flex-column">
            <span class="d-block font-weight-semibold">{{ item.request_pk }}</span>
          </div>
        </template>

        <template #[`item.action`]="{ item }">
          <v-btn
            v-if="item.request_status != 'COMPLETED'"
            small
            color="primary"
            @click="completeGiftRequest(item.request_pk)"
          >
            Выдать
          </v-btn>
          <v-btn
            v-if="item.request_status != 'COMPLETED'"
            small
            color="error"
            class="ml-2"
            @click="deleteGiftRequest(item.request_pk)"
          >
            Удалить
          </v-btn>

          <span v-if="item.request_status == 'COMPLETED'"> - </span>
        </template>

        <template #[`item.request_status`]="{ item }">
          <v-chip class="font-weight-medium" :color="statusColor[item.request_status]" small>
            {{ status[item.request_status] }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiAccountOutline } from '@mdi/js'
import { ref } from 'vue'
import Api from '@/api/api'
import { mapActions } from 'vuex'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },
  setup() {
    const loading = false
    const loader = false
    const statusColor = {
      CREATED: 'info',
      PROCESSING: 'danger',
      COMPLETED: 'success',
    }
    return {
      headers: [
        { text: 'ID', value: 'request_pk', align: 'start', width: '100' },
        { text: 'Подарок', value: 'gift_title' },
        { text: 'Пользователь', value: 'user_name' },
        { text: 'Дата', value: 'date' },
        { text: 'Статус', value: 'request_status' },
        { text: 'Дейстие', value: 'action' },
      ],
      headers_rooms: [{ text: 'Номер', value: 'name', align: 'start' }],
      status: {
        CREATED: 'cоздан',
        PROCESSING: 'в обработке',
        COMPLETED: 'выдан',
      },
      loader,
      loading,
      statusColor,
    }
  },
  data() {
    return {
      errorMessages: '',
      active_project: [],
      gift_list: [],
    }
  },
  async mounted() {
    this.giftRequests()
  },
  methods: {
    giftRequests() {
      this.loading = true
      try {
        Api.giftRequests().then(
          (event => {
            this.$set(this, 'gift_list', event)
            this.$set(this, 'loading', false)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    completeGiftRequest(gift_request_pk) {
      if (confirm('Вы уверены что хотите выдать подарок?')) {
        this.loading = true
        try {
          Api.completeGiftRequest({
            gift_request_pk: gift_request_pk,
          }).then(
            (event => {
              this.$set(this, 'loading', false)
              this.giftRequests()
            }).bind(this),
          )
        } catch (error) {
          console.log(error)
        }
      }
    },
    deleteGiftRequest(gift_request_pk) {
      if (confirm('Вы уверены что хотите удалить запрос на получение подарока?')) {
        this.loading = true
        try {
          Api.deleteGiftRequest({
            gift_request_pk: gift_request_pk,
          }).then(
            (event => {
              this.$set(this, 'loading', false)
              this.giftRequests()
            }).bind(this),
          )
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
  computed: {
    form() {
      return {
        cert_sum: this.cert_sum,
        project_pk: this.project_pk,
      }
    },
  },
  watch: {
    with_bill() {
      if (!this.with_bill) {
        this.cert_sum = null
      }
    },
  },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate: function (value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    },
  },
}
</script>
<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.search_calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mx-input {
  height: 48px;
}
</style>
