import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import reservation from "./modules/reservation";
import event from "./modules/event";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        auth,
        reservation,
        event
    },
    plugins: [createPersistedState()],
});