<template>
  <div>
    <h1>{{ $store.getters.event.header }}</h1>
    <h1 class="mb-5">Новое бронирование</h1>
    <v-row>
      <v-col cols="7">
        <v-card>
          <v-card-title>Укажите пользователя</v-card-title>
          <v-card-text>
            <v-autocomplete
              v-model="select_user"
              :loading="loading"
              :items="users_list"
              item-text="email"
              item-value="id"
              value="id"
              :search-input.sync="search_user"
              autofocus
              label="Поиск пользователя"
            ></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="reservation_object && select_user">
      <v-row>
        <v-col cols="7" class="mb-6" xs="12" sm="12" md="12" lg="7">
          <v-card class="overflow-hidden">
            <v-row class="ma-0 h-full">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-card-title class="mt-2" style="font-size: 25px; font-weigth: bold">
                  {{ reservation_object.reservation.header }}
                </v-card-title>

                <v-card-text class="mb-0 pb-0">
                  <v-divider></v-divider>
                </v-card-text>
                <div :key="room.id" v-for="room in reservation_object.reservation.suits">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <v-card-title> {{ room.name }} №{{ room.room_number }}</v-card-title>
                      <v-card-subtitle class="text-xs pb-0"> {{ room.options.package }} <br /> </v-card-subtitle>
                    </div>
                    <v-btn x-small depressed @click="deleteRoom(reservation_object.uuid, room.id)" color="error"
                      >Удалить номер</v-btn
                    >
                  </div>

                  <div>
                    <v-card-title class="pb-0 mb-0">Тип номера</v-card-title>
                    <v-card-text>
                      <v-radio-group v-model="room.options.type">
                        <v-radio
                          v-for="(t, key) in room.room_available_type"
                          :key="t"
                          :label="t"
                          :value="key"
                        ></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </div>

                  <div v-show="room.options.type == '1'">
                    <v-card-title class="pb-0 mb-0">Пол участника</v-card-title>
                    <v-card-text>
                      <v-radio-group v-model="room.options.gender">
                        <v-radio v-for="(g, key) in room.available_gender" :key="g" :label="g" :value="key"></v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </div>

                  <v-card-text>
                    <v-divider></v-divider>
                  </v-card-text>
                  <div :key="participant.index" v-for="(participant, index) in room.participants">
                    <v-card-title
                      ><span>Данные {{ index + 1 }}-го участника </span>
                      <v-spacer></v-spacer>
                      <v-btn x-small depressed @click="deletePeople(room.participants, index)" color="error"
                        >Удалить</v-btn
                      >
                    </v-card-title>

                    <v-card-text>
                      <v-text-field
                        outlined
                        hide-details
                        v-model="participant.name"
                        label="Фамилия Имя"
                        required
                      ></v-text-field>

                      <v-checkbox
                        v-model="participant.show_in_list"
                        class="mt-2"
                        label="Показывать имя в списке участников или отобразить как ***"
                      ></v-checkbox>

                      <div class="d-flex" style="margin-bottom: 30px">
                        <div>
                          <div class="label">Дата заезда</div>
                          <date-picker
                            v-model="participant.start"
                            :lang="lang"
                            type="date"
                            valueType="format"
                            class="v-input__control mr-md-4 mr-sm-4"
                            :format="'YYYY-MM-DD'"
                          ></date-picker>
                        </div>
                        <div>
                          <div class="label">Дата выезда</div>
                          <date-picker
                            v-model="participant.end"
                            :lang="lang"
                            type="date"
                            valueType="format"
                            class="v-input__control mr-md-4 mr-sm-4"
                            :format="'YYYY-MM-DD'"
                          ></date-picker>
                        </div>
                      </div>

                      <label for="participantType">Тип Питания</label>
                      <v-radio-group mandatory v-model="participant.food" id="participantType">
                        <v-radio label="Стандартное" value="Стандартное"></v-radio>
                        <v-radio label="Вегетарианское" value="Вегетарианское"></v-radio>
                      </v-radio-group>

                      <label for="participantType">Тип участника</label>
                      <v-radio-group mandatory v-model="participant.participantType" id="participantType">
                        <v-radio label="Спортсмен" value="Спортсмен"></v-radio>
                        <v-radio label="Сопровождающий" value="Сопровождающий"></v-radio>
                      </v-radio-group>

                      <label for="participantType">Возраст участника</label>
                      <v-radio-group mandatory v-model="participant.age" id="participantType">
                        <v-radio label="Взрослый" value="Взрослый"></v-radio>
                        <v-radio
                          label="Ребенок (до 13 лет включительно)"
                          value="Ребенок (до 13 лет включительно)"
                        ></v-radio>
                      </v-radio-group>

                      <v-text-field
                        outlined
                        hide-details
                        v-show="participant.age != 'Взрослый'"
                        label="Полных лет на дату заезда"
                        class="v-label_small"
                        v-model="participant.childAge"
                      ></v-text-field>

                      <v-checkbox
                        hide-details
                        v-model="participant.insurance"
                        label="Добавить страховку от невыезда"
                      ></v-checkbox>
                      <v-text-field
                        outlined
                        hide-details
                        v-model="participant.discount_admin"
                        label="Скидка"
                        class="v-label_small mt-5"
                        required
                      ></v-text-field>
                      <v-text-field
                        outlined
                        hide-details
                        v-model="participant.discount_text"
                        label="discount_text"
                        class="v-label_small mt-5"
                        required
                      ></v-text-field>
                      <v-checkbox
                        class=""
                        v-model="participant.no_count"
                        label="Скрыть участника из всех списков"
                      ></v-checkbox>
                      <v-divider class="mt-10"></v-divider>
                    </v-card-text>
                  </div>
                  <v-btn
                    x-small
                    depressed
                    class="small-btn"
                    v-if="room.participants.length < room.capacity && room.options.type !== 0"
                    @click="addPeople(room.participants)"
                    color="primary"
                    >Добавить участника</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-btn class="ma-0 mb-5 mt-5" @click="updateReservation()" color="success ml-5">Сохранить</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'
import router from '@/router'
import { mdiCursorDefaultGestureOutline } from '@mdi/js'
import AddRoomModal from '@/views/events/AddRoomModal.vue'

export default {
  components: { AddRoomModal },
  data() {
    return {
      reservation_object: null,
      users_list: [],
      search_user: null,
      select_user: null,
      loading: false,
      new_reservation_popup: false,
      free_event_rooms: [],
      loader: false,
    }
  },
  created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    await this.getFrontReservation()
    await this.getUsers()
  },
  methods: {
    async getFrontReservation() {
      try {
        Api.getReservation({
          reservation_uuid: this.$store.getters.reservation.user_reservation_uuid,
        })
          .then(
            (event => {
              this.$set(this, 'reservation_object', event)
            }).bind(this),
          )
          .catch(error => {
            if (error.response.status === 400) {
              this.$store.dispatch('clearReservationData')
              this.$router.push({
                path: '/events/' + this.$route.params.id,
                replace: true,
              })
            } else {
              // Handle else
            }
          })
      } catch (error) {
        this.$router.push({
          path: '/events/' + this.$route.params.id,
          replace: true,
        })
      }
    },
    async getUsers() {
      try {
        Api.getUsers().then(
          (event => {
            this.$set(this, 'users_list', event)
          }).bind(this),
        )
      } catch {}
    },
    async updateReservation() {
      try {
        Api.updateReservation(this.reservation_object).then(
          (event => {
            this.$store.dispatch('clearReservationData')

            Api.reservationSuccess({
              reservation_uuid: this.reservation_object.uuid,
              user_id: this.reservation_object.user_id,
            })
              .then(
                (event => {
                  this.$router.push({
                    path: '/events/' + this.$route.params.id + '/' + this.reservation_object.reservation_uuid,
                    replace: true,
                  })
                }).bind(this),
              )
              .catch(error => {
                if (error.response.status === 400) {
                } else {
                }
                console.log(error.response)
              })
          }).bind(this),
        )
      } catch {}
    },
    getFreeEventRooms() {
      try {
        this.loader = true
        Api.getFreeEventRooms({
          event_id: parseInt(this.$route.params.id),
        }).then(
          (event => {
            this.$set(this, 'free_event_rooms', event)
            this.$set(this, 'new_reservation_popup', true)
            this.$set(this, 'loader', false)
          }).bind(this),
        )
      } catch (error) {
        this.$set(this, 'loader', false)
      }
    },
    async checkInvoice() {
      try {
        Api.checkInvoice({
          reservation_uuid: this.reservation_object.reservation_uuid,
        }).then(
          (event => {
            this.$router.push({
              path: '/events/' + this.$route.params.id + '/' + this.$route.params.reservation_id,
              replace: true,
            })
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    deletePeople(array, index) {
      // console.log(participant, index)
      array.splice(index, 1)
    },
    async deleteRoom(reservation_uuid, event_room_id) {
      try {
        Api.deleteReservation({
          reservation_uuid: reservation_uuid,
          event_room_id: event_room_id,
        }).then(
          (event => {
            this.getFrontReservation()
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    addPeople(array) {
      array.push({
        age: '',
        childAge: null,
        gender: '',
        food: '',
        insurance: false,
        show_in_list: true,
        no_count: false,
        name: '',
        participantType: '',
        phone: '',
      })
    },
  },
  watch: {
    select_user(val) {
      this.reservation_object.user_id = val
    },
  },
}
</script>
<style lang="scss" scoped></style>
