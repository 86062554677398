<template>
  <div>
    <v-btn :to="'/events/' + $route.params.id" class="ma-0 mb-5">Назад </v-btn>
    <h1>{{ $store.getters.event.header }}</h1>
    <h3>Пакеты мероприятий</h3>
    <v-card id="account-setting-card">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Название</th>
              <th class="text-left">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in packages" :key="item.name">
              <td>{{ item.name }}</td>
              <td>
                <router-link :to="'/events/' + $route.params.id + '/packages/' + item.id" class="ma-0 mb-5"
                  >Редактировать</router-link
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <p class="mt-5">Создание пакета мероприятий осуществляется через суперадминку</p>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccount,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiCurrencyRub,
  mdiBedKing,
  mdiRoomService,
  mdiCart,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiAccountGroup,
  mdiEye,
  mdiSwapHorizontal,
  mdiLeadPencil,
  mdiContactlessPaymentCircle,
  mdiShieldAccount,
  mdiSale,
} from '@mdi/js'

import { ref } from 'vue'
import Api from '@/api/api'
import { mapActions } from 'vuex'
import MyLoader from '@/components/MyLoader.vue'

export default {
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiCurrencyRub,
        mdiBedKing,
        mdiCart,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiAccount,
        mdiAccountGroup,
        mdiEye,
        mdiSwapHorizontal,
        mdiLeadPencil,
        mdiContactlessPaymentCircle,
        mdiShieldAccount,
        mdiSale,
      },
    }
  },
  data() {
    return {
      packages: Array,
    }
  },
  async mounted() {
    this.getPackagesByEventId()
  },
  methods: {
    getPackagesByEventId() {
      try {
        Api.getPackagesByEventId({
          event_id: parseInt(this.$route.params.id),
        }).then(
          (event => {
            this.$set(this, 'packages', event)
          }).bind(this),
        )
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.range_object {
  flex: 1;
  border-left: 1px solid rgb(186, 186, 186);
  text-align: center;
  font-size: 10px;
  padding: 10px 0;

  &:first-child {
    border-left: none;
  }
}

.people_in_room_block {
  margin-top: 3px;
  background-color: #6b36cb !important;
  color: #fff;
}

.reservation_wrap {
  padding: 10px 0;
}

.reservation_block {
  position: relative;
  min-height: 30px;
}

.reservation_people_wrap {
  margin-top: 10px;

  .reservation_wrap {
    padding: 0;
    margin-bottom: 5px;
  }
}

.reservation_block_item {
  position: relative;
  min-height: 25px;

  .reservation {
    position: absolute;
    background-color: #673bb8;
    color: #fff;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 13px;
  }
}

.reservation {
  position: absolute;
  background-color: #9155fd;
  color: #fff;
  border-radius: 20px;
  padding: 0px 10px;
  font-size: 13px;
}

.reservation_confirm {
  font-weight: bold;
}
</style>
