<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <p class="text-2xl mb-6">
      Акции
    </p>

    <div>
      <ul class="btn_list">
        <li>
          <v-btn @click="accountReplenishmentPromotions" color="primary">
            Акции пополнения счета
          </v-btn>
        </li>
      </ul>
    </div>

    <div>
      <ul class="btn_list">
        <li>
          <v-btn @click="subscriptionSalesPromotions" color="primary">
            Акции продажи абонементов
          </v-btn>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
  },

  setup() {
    const loading = false
    const loader = false
    return {
      loader,
      loading,
    }
  },

  methods: {
    accountReplenishmentPromotions() {
      window.open('https://lk.xbadm.ru/admin/sales/promo/', '_blank')
    },

    subscriptionSalesPromotions() {
      window.open('https://lk.xbadm.ru/admin/sales/promoabonementsale/', '_blank')
    },
  },
}
</script>

<style scoped>
.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
