<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <div v-if="promocodes_list">
      <p class="text-2xl mb-6">Список промокодов</p>

      <div>
        <ul class="btn_list">
          <li>
            <v-btn @click="promocode_popup = true" color="primary"> Добавить промокод </v-btn>
          </li>
        </ul>
      </div>

      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск промокода"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="promocodes_list"
          :search="search"
          :loading="loading"
          loading-text="Загрузка данных"
          class="elevation-1"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex flex-column">
              <router-link :to="'/promocodes/' + item.id">
                <span class="d-block font-weight-semibold">{{ item.id }}</span>
              </router-link>
            </div>
          </template>

          <template #[`item.users`]="{ item }">
            <p class="mt-2" v-for="(dpt, index) in item.users" :key="index">
              {{ dpt.email }}
            </p>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog v-model="promocode_popup" transition="" max-width="600" persistent>
      <v-card>
        <v-toolbar color="primary" dark> Создать промокод </v-toolbar>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <autocomplete @updateParent="updateSelected"></autocomplete>

            <v-text-field
              v-model="toUp"
              @keyup="uppercase"
              :rules="[v => !!v || 'Обязательное поле']"
              class="toUp"
              label="Промокод"
              required
            ></v-text-field>

            <div>
              <v-select
                v-model="promo_event"
                :items="events_list"
                item-text="title"
                item-value="value"
                label="Название сборов"
                :rules="[v => !!v || 'Обязательное поле']"
              ></v-select>
            </div>

            <v-text-field
              type="number"
              v-model.number="promocode_name"
              :rules="nameRules"
              label="Скидка"
              required
              hide-spin-buttons
            ></v-text-field>

            <template>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    handler
                    v-model="dateFormatted"
                    label="Срок"
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
              </v-menu>
            </template>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="createPromocodes()"> Создать промокод </v-btn>
          <v-btn
            color="error"
            @click="
              promocode_popup = false
              reset()
            "
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAccountOutline } from '@mdi/js'
import { ref } from 'vue'
import Api from '@/api/api'
import { mapActions } from 'vuex'
import MyLoader from '@/components/MyLoader.vue'
import event from '@/store/modules/event'
import Autocomplete from '@/components/Autocomplete.vue'

export default {
  components: { MyLoader, Autocomplete },
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },

  setup() {
    const loading = false
    const loader = false
    const promocode_name = ''
    const selected_user = ''
    const statusColor = {
      CREATED: 'info',
      PROCESSING: 'danger',
      COMPLETED: 'success',
    }

    return {
      headers: [
        { text: 'ID', value: 'id', align: 'start', width: '65' },
        { text: 'Промокод', value: 'name', width: '65' },
        { text: 'Скидка', value: 'discount', width: '100' },
        { text: 'Идентификатор события', value: 'event_id', width: '200' },
        { text: 'Название', value: 'event_name', width: '250' },
        { text: 'Клиент', value: 'users', width: '100' },
        { text: 'Срок', value: 'expired_date', width: '125' },
        { text: 'Использованно', value: 'used' },
      ],

      valid: '',
      loader,
      loading,
      statusColor,
      selected_user,

      promocode_name,
      nameRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v > 9) || 'Минимальная скидка 10%',
        v => (v && v < 51) || 'Максимальная скидка 50%',
      ],
    }
  },
  data() {
    return {
      errorMessages: '',
      menu1: false,
      active_project: [],
      promocodes_list: [],
      events_list: [],
      search: null,
      promocode_popup: false,
      fee_filter: false,
      toUp: '',
      promo_event: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
    }
  },

  async mounted() {
    this.getPromocodes()
    this.getAllEvents()
  },

  methods: {
    formatDate(date) {
      if (!date) return null
      console.log(date)
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },

    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    getPromocodes() {
      this.loading = true
      try {
        Api.getPromocodes().then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'promocodes_list', event)
            this.promocodes_list = event
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },

    getAllEvents() {
      this.loading = true
      try {
        Api.getAllEvents().then(
          (event => {
            event.forEach(item => {
              this.events_list.push({ title: item.title, value: item.id })
            })
            this.$set(this, 'loading', false)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },

    async createPromocodes() {
      this.loading = true
      console.log(this.toUp)
      console.log(this.promocode_name)
      console.log(this.promo_event)
      console.log(this.selected_user.id)
      console.log(this.dateFormatted)
      if (this.$refs.form.validate()) {
        Api.createPromocodes({
          name: this.toUp,
          discount: parseInt(this.promocode_name),
          event_id: this.promo_event,
          user_id: this.selected_user.id,
          expired_date: this.dateFormatted,
        })
          .then(
            (event => {
              this.getPromocodes()
              this.reset()
              this.loading = false
              this.promocode_popup = false
              console.log(event)
            }).bind(this),
          )
          .catch(error => {
            if (error.response.status === 400) {
              alert('Ошибка 400 (неверный запрос)')
              this.$store.dispatch('clearReservationData')
              this.$router.push({
                path: '/promocodes/' + this.$route.params.id,
                replace: true,
              })
            } else {
              console.log('no valid')
            }
          })
      }
    },

    uppercase() {
      this.toUp = this.toUp.toUpperCase()
    },

    reset() {
      this.$refs.form.reset()
    },

    updateSelected(data) {
      console.log(data)
      this.selected_user = data
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date)
    },

    with_bill() {
      if (!this.with_bill) {
        this.cert_sum = null
      }
    },
  },

  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate: function (value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    },
  },
}
</script>

<style scoped>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.search_calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
