<template>
  <div v-if="clients_list">
    <p class="text-2xl mb-6">Список клиентов</p>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск клиента"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="clients_list" :search="search" :loading="loading" class="elevation-1">
        <template v-slot:[`item.id`]="{ item }">
          <div class="d-flex flex-column">
            <router-link :to="'/clients/' + item.id">
              <span class="d-block font-weight-semibold">{{ item.id }}</span>
            </router-link>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Event from './Event.vue'
import Api from '@/api/api'

export default {
  components: {
    Event,
  },
  data() {
    return {
      clients_list: [],
      search: '',
      loading: false,
      headers: [
        { text: 'id', align: 'start', value: 'id', sortable: false },
        { text: 'Имя', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Телефон', value: 'phone', sortable: false },
        { text: 'Коэффициент активности', value: 'main_coef', sortable: true },
        { text: 'Статус', value: 'user_status', sortable: true },
      ],
    }
  },
  mounted() {
    this.get_clients()
  },
  methods: {
    async get_clients() {
      this.loading = true
      try {
        Api.getClients().then(
          (event => {
            this.$set(this, 'clients_list', event)
            this.$set(this, 'loading', false)
          }).bind(this),
        )
      } catch {}
    },
  },
}
</script>
