<template>
  <span>
    <v-btn @click="getFreeEventRooms()" class="ma-0 mb-5 ml-5" color="success">{{ title }}</v-btn>
    <v-dialog v-model="new_reservation_popup" width="100%">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Выберете номер </v-card-title>
        <template>
          <v-tabs v-model="room_tabs" align-with-title>
            <v-tab v-for="packages in free_event_rooms.packages" :key="packages.id">
              {{ packages.packageName }}
            </v-tab>
          </v-tabs>
        </template>

        <v-tabs-items v-model="room_tabs">
          <v-tab-item v-for="item in free_event_rooms.packages" :key="item.id">
            <v-simple-table>
              <template v-if="free_event_rooms">
                <thead>
                  <tr>
                    <th class="text-left">Id</th>
                    <th>Номер</th>
                    <th>Тип номера</th>
                    <th>Пол номера</th>
                    <th>Свободных мест</th>
                    <th>Действие</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="suit in item.suits" :key="suit.id">
                    <td>{{ suit.id }}</td>
                    <td>{{ suit.name }} - {{ suit.room_number }}</td>
                    <td>{{ suit.order_room_type_name }}</td>
                    <td>{{ suit.gender_room_type_name }}</td>
                    <td>{{ suit.free_places }}</td>
                    <td><v-btn @click="SelectRoom(suit.id)" x-small color="success">Выбрать</v-btn></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <my-loader :loader="loader"></my-loader>
  </span>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  props: {
    title: String,
  },
  data() {
    return {
      room_tabs: null,
      headers_rooms: [{ text: 'Номер', value: 'name', align: 'start' }],
      new_reservation_popup: false,
      free_event_rooms: [],
      loader: false,
    }
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  methods: {
    getFreeEventRooms() {
      try {
        this.loader = true
        Api.getFreeEventRooms({
          event_id: parseInt(this.$route.params.id),
        }).then(
          (event => {
            this.$set(this, 'free_event_rooms', event)
            this.$set(this, 'new_reservation_popup', true)
            this.$set(this, 'loader', false)
          }).bind(this),
        )
      } catch (error) {
        this.$set(this, 'loader', false)
      }
    },
    SelectRoom(event_room_id) {
      this.$set(this, 'new_reservation_popup', false)
      this.$emit('add-reservation', event_room_id)
    },
  },
}
</script>
