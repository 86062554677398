<template>
  <div v-if="events_list">
    <p class="text-2xl mb-6">Список сборов</p>
    <event :events="events_list"></event>
  </div>
</template>

<script>
import Api from '@/api/api'
import Event from './Event.vue'

export default {
  components: {
    Event,
  },
  data() {
    return {
      events_list: [],
    }
  },
  mounted() {
    this.get_all_events()
  },
  methods: {
    async get_all_events() {
      try {
        Api.getEvents().then(event => {
          this.$set(this, 'events_list', event)
        })
      } catch {
        console.log('error')
      }
    },
  },
}
</script>
