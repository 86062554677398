<template>
  <v-autocomplete
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    return-object
    hide-no-data
    hide-selected
    no-filter
    label="Выберите клиента"
    item-text="name"
    item-value="id"
    @change="updateSelected()"
  >
  </v-autocomplete>
</template>
<script>
import Api from '@/api/api'

export default {
  props: {},
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
    }
  },
  watch: {
    search() {
      if (this.search) {
        if (this.search.length > 3) {
          this.get_clients()
        }
      }
    },
  },
  methods: {
    async get_clients() {
      this.loading = true
      try {
        Api.getClients({
          search: this.search,
        }).then(
          (event => {
            this.$set(this, 'items', event)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    updateSelected() {
      this.$emit('updateParent', this.select)
    },
  },
}
</script>
