<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <div v-if="tax_deduction_list">
      <p class="text-2xl mb-6">Налоговый вычет | Список справок</p>
      <div>
        <ul class="btn_list">
          <li>
            <v-btn @click="tax_deduction_popup = true" color="primary"> Внести платеж </v-btn>
          </li>
        </ul>
      </div>

      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск справки"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="tax_deduction_list"
          :search="search"
          :loading="loading"
          loading-text="Загрузка данных"
          class="elevation-1"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex flex-column">
              <router-link :to="'/tax_deduction/' + item.id">
                <span class="d-block font-weight-semibold">{{ item.id }}</span>
              </router-link>
            </div>
          </template>

          <template #[`item.complete`]="{ item }">
            <v-chip class="font-weight-medium" :color="statusColor[item.complete]" small>
              {{ status[item.complete] }}
            </v-chip>
          </template>

          <template #[`item.sum`]="{ item }"> {{ item.sum | format }} ₽ </template>
        </v-data-table>
      </v-card>
    </div>

    <my-loader :loader="loader"></my-loader>

    <v-dialog v-model="tax_deduction_popup" transition="" max-width="600" persistent>
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-toolbar color="primary" dark> Внести платеж из кассы «Ручная(безналичная касса НП ПБК Химки)» </v-toolbar>

          <v-card-text class="mt-5">
            <autocomplete @updateParent="updateSelected"></autocomplete>
            <v-text-field v-model="amount" :rules="amount_rules" label="Сумма платежа" required></v-text-field>
            <v-text-field
              v-model="yc_document_id"
              :rules="yc_document_id_rules"
              label="Номер документа"
              required
            ></v-text-field>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn :disabled="!valid" color="success" class="mr-4" @click="addPay"> Внести </v-btn>
            <v-btn color="error" @click=";(tax_deduction_popup = false), reset()"> Закрыть </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'
import Autocomplete from '@/components/Autocomplete.vue'

export default {
  components: { MyLoader, Autocomplete },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
  },
  data() {
    return {
      loading: false,
      loader: false,
      headers: [
        { text: 'Номер', value: 'id', align: 'start' },
        { text: 'Клиент', value: 'user_name' },
        { text: 'Телефон', value: 'user_phone' },
        { text: 'Email', value: 'user_email' },
        { text: 'Сумма', value: 'sum' },
        { text: 'Статус выдачи', value: 'complete' },
        { text: 'Дата создания запроса', value: 'created_at' },
      ],
      errorMessages: '',
      active_project: [],
      tax_deduction_list: [],
      search: null,
      tax_deduction_popup: false,
      status: {
        true: 'выдана',
        false: 'не выдана',
      },
      statusColor: {
        false: 'info',
        true: 'success',
      },
      valid: false,
      selected_user: null,
      amount: null,
      amount_rules: [
        v => !!v || 'Сумма платежа обязательное поле',
        v => (v && v > 0) || 'Сумма платежа должна быть больше 0',
      ],
      yc_document_id: null,
      yc_document_id_rules: [v => !!v || 'Номер документа обязательное поле'],
    }
  },
  watch: {
    with_bill() {
      if (!this.with_bill) {
        this.cert_sum = null
      }
    },
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getTaxDeduction()
  },
  methods: {
    getTaxDeduction() {
      this.loading = true
      try {
        Api.getTaxDeduction().then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'tax_deduction_list', event.data)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    updateSelected(data) {
      this.selected_user = data
    },
    addPay() {
      if (this.valid) {
        this.loader = true
        try {
          Api.addManualOrder({
            user_id: this.selected_user.id,
            amount: this.amount,
            yc_document_id: this.yc_document_id,
          }).then(
            (event => {
              this.$set(this, 'loader', false)
              if (event.status === 'created') {
                alert('Платеж успешно создан')
                this.tax_deduction_popup = false
                this.reset()
              }
            }).bind(this),
          )
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style scoped>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
