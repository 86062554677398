<template>
  <div>
    <v-btn :to="'/events/' + $route.params.id + '/packages'" class="ma-0 mb-5">Назад </v-btn>
    <h1>{{ $store.getters.event.header }}</h1>
    <h3>{{ package.name }}</h3>
    <v-card>
      <v-data-table
        :loading="loading"
        v-if="package"
        :items-per-page="100"
        :headers="headers"
        :items="package.prices"
        item-value="name"
        loading-text="Загрузка данных"
        hide-default-footer
      >
        <template v-slot:item="{ index, item }">
          <tr :class="{ change: item.change }">
            <td>{{ item.event_room_type }}</td>
            <td>{{ item.name }}</td>
            <td>
              <v-text-field
                @paste="handlePaste(index, $event)"
                v-model="item.price_1"
                label="Цена 1"
                required
              ></v-text-field>
            </td>
            <td><v-text-field v-model="item.price_2" label="Цена 2" required></v-text-field></td>
            <td><v-text-field v-model="item.price_3" label="Цена 3" required></v-text-field></td>
            <td><v-text-field v-model="item.price_4" label="Цена 4" required></v-text-field></td>
            <td><v-text-field v-model="item.price_5" label="Цена 5" required></v-text-field></td>
            <td><v-text-field v-model="item.price_6" label="Цена 6" required></v-text-field></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-btn class="mt-5" @click="updatePackageById()" color="success ml-5">Сохранить</v-btn>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccount,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiCurrencyRub,
  mdiBedKing,
  mdiRoomService,
  mdiCart,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiAccountGroup,
  mdiEye,
  mdiSwapHorizontal,
  mdiLeadPencil,
  mdiContactlessPaymentCircle,
  mdiShieldAccount,
  mdiSale,
} from '@mdi/js'

import { ref } from 'vue'
import Api from '@/api/api'
import { mapActions } from 'vuex'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },
  setup() {
    const tab = ref('')
    const reservationList = []
    const loading = true
    const loader = false
    const statusColor = {
      Оплачен: 'success',
      Частично: 'warning',
      Неоплачен: 'error',
      Переплата: 'error',
    }

    return {
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiCurrencyRub,
        mdiBedKing,
        mdiCart,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiAccount,
        mdiAccountGroup,
        mdiEye,
        mdiSwapHorizontal,
        mdiLeadPencil,
        mdiContactlessPaymentCircle,
        mdiShieldAccount,
        mdiSale,
      },
      loading,
    }
  },
  data() {
    return {
      package: [],
      headers: [
        { text: 'Номер', value: 'event_room_type' },
        { text: 'Размещение', value: 'name' },
        { text: 'Цена 1', value: 'price_1' },
        { text: 'Цена 2', value: 'price_2' },
        { text: 'Цена 3', value: 'price_3' },
        { text: 'Цена 4', value: 'price_4' },
        { text: 'Цена 5', value: 'price_5' },
        { text: 'Цена 6', value: 'price_6' },
      ],
    }
  },
  async mounted() {
    this.getPackageById()
  },
  methods: {
    getPackageById() {
      this.loading = true
      try {
        Api.getPackageById({
          package_id: parseInt(this.$route.params.package_id),
        }).then(
          (event => {
            this.$set(this, 'package', event)
            this.$set(this, 'loading', false)
          }).bind(this),
        )
      } catch (error) {
        alert(error)
      }
    },
    async handlePaste(index, event) {
      let clipboardData = event.clipboardData || window.clipboardData
      let pastedText = clipboardData.getData('text')

      setTimeout(() => {
        let prices = pastedText.split('\t')
        this.package.prices[index]['price_1'] = ''
        for (let i = 0; i < prices.length; i++) {
          let count = i + 1
          this.package.prices[index]['price_' + count] = parseInt(prices[i].replace(/\D/g, ''))
        }

        this.package.prices[index].change = true
      }, 300)
    },
    async updatePackageById() {
      try {
        Api.updatePackageById({
          package_id: parseInt(this.$route.params.package_id),
          data: this.package.prices,
        }).then(
          (event => {
            this.getPackageById()
          }).bind(this),
        )
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
tr.change {
  background: #56ca0033 !important;
}
</style>
