import axios from "axios"


const state = {
    reservation: null,
};

const getters = {
    reservation: (state) => state.reservation,
};

const actions = {
    async addReservationData({ commit }, data) {
        await commit("setReservation", data);
    },

    async clearReservationData({ commit }) {
        await commit("unSetReservation");
    },

};

const mutations = {
    setReservation(state, reservation) {
        state.reservation = reservation;
    },

    unSetReservation(state) {
        state.reservation = null;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};