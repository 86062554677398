import axios from "axios"


const state = {
    event: null,
};

const getters = {
    event: (state) => state.event,
};

const actions = {
    async addEventnData({ commit }, data) {
        await commit("setEvent", data);
    },

    async clearEventData({ commit }) {
        await commit("unSetEvent");
    },

};

const mutations = {
    setEvent(state, event) {
        state.event = event;
    },

    unSetEvent(state) {
        state.event = null;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};