<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center">
      <h1 class="mb-5">Участники программы клубный игрок</h1>
    </div>

    <div style="margin-bottom: 20px">
      <router-link :to="'/club_player'">
        <span class="d-block font-weight-semibold">Список запросов</span>
      </router-link>
    </div>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск клиента"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="100"
        :headers="headers"
        :search="search"
        :items="client_list"
        item-key="request_pk"
        :loading="loading"
        class="elevation-1"
        loading-text="Загрузка данных"
      >
        <template #[`item.name`]="{ item }">
          <a @click="getClubPlayerClientHistory(item)">
            <span style="text-decoration: underline">{{ item.name }}</span>
          </a>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="clubplayer_popup" transition="dialog-top-transition">
      <v-card v-if="active_player">
        <v-card-title>
          <span class="text-h5">{{ active_player.name }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6" sm="12" md="12">
              <span class="text-h5">История запросов подарков</span>
              <v-simple-table v-if="client_history">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Дата запроса</th>
                      <th class="text-left">Дата выдачи</th>
                      <th class="text-left">Подарок</th>
                      <th class="text-left">Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in client_history.gift_requests" :key="index">
                      <td>
                        {{ item.created_at }}
                      </td>
                      <td>
                        {{ item.completed_at }}
                      </td>
                      <td>
                        {{ item.gift }}
                      </td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6" sm="12" md="12">
              <span class="text-h5">История начисления баллов</span>
              <v-simple-table v-if="client_history">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Дата</th>
                      <th class="text-left">Начисление/списание</th>
                      <th class="text-left">Название</th>
                      <th class="text-left">Баллы</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in client_history.points_history" :key="index">
                      <td>
                        <template>
                          {{ item.date }}
                        </template>
                      </td>
                      <td>
                        <template v-if="item.type == 'UP'">Начисление</template>
                        <template v-else> Списание </template>
                      </td>
                      <td>
                        <template v-if="item.yc_data">{{ item.yc_data.services[0].title }}</template>
                        <template v-else> - </template>
                      </td>
                      <td>{{ item.points_amount }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clubplayer_popup = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAccountOutline } from '@mdi/js'
import { ref } from 'vue'
import Api from '@/api/api'
import { mapActions } from 'vuex'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },
  setup() {
    const loading = false
    const loader = false
    const statusColor = {
      CREATED: 'info',
      PROCESSING: 'danger',
      COMPLETED: 'success',
    }
    return {
      headers: [
        { text: 'ID', value: 'user_id', align: 'start', width: '100' },
        { text: 'yc_id', value: 'yc_id' },
        { text: 'Имя', value: 'name' },
        { text: 'Телефон', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Баллы', value: 'club_player_points' },
      ],
      headers_rooms: [{ text: 'Номер', value: 'name', align: 'start' }],
      status: {
        CREATED: 'cоздан',
        PROCESSING: 'в обработке',
        COMPLETED: 'выдан',
      },
      loader,
      loading,
      statusColor,
    }
  },
  data() {
    return {
      errorMessages: '',
      client_list: [],
      client_history: null,
      search: '',
      clubplayer_popup: false,
      active_player: null,
    }
  },
  async mounted() {
    this.getClubPlayerClients()
  },
  methods: {
    getClubPlayerClients() {
      this.loading = true
      try {
        Api.getClubPlayerClients().then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'client_list', event.users)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    getClubPlayerClientHistory(user) {
      this.loading = true
      this.active_player = user
      try {
        Api.getClubPlayerClientHistory(user.user_id).then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'client_history', event)
            this.$set(this, 'clubplayer_popup', true)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    formatDate(string) {
      const inputString = string
      const datePart = inputString.split(' ')[0]
      const timePart = inputString.split(' ')[1]
      const [year, month, day] = datePart.split('-')
      const [hour, minute, second] = timePart.split(':')
      const formattedDate = `${day}.${month}.${year} ${hour}:${minute}`

      return formattedDate
    },
  },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate: function (value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    },
  },
}
</script>
<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.search_calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mx-input {
  height: 48px;
}
</style>
