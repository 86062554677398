<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center">
      <h1 class="mb-5">Сертификаты</h1>
      <v-btn class="ma-0 mb-5" color="primary" @click="create_cert_dialog = true">+ Создать</v-btn>
      <v-btn class="ma-0 mb-5 ml-3" :class="{ info: isFilterActive }" @click="isFilterActive = !isFilterActive">
        Фильтры
      </v-btn>
    </div>
    <v-text-field
      solo
      label="Поиск по номеру"
      v-model="cert_numb"
      @input="cert_numb = cert_numb.toUpperCase()"
    ></v-text-field>
    <div class="filters" v-show="isFilterActive">
      <h5 class="mb-5">Фильтры</h5>
      <div class="search_calendar d-flex flex-wrap">
        <div class="calendar_search_block">
          <div class="label">Дата создания от</div>
          <date-picker
            v-model="created_from"
            :lang="lang"
            type="date"
            valueType="format"
            class="v-input__control mr-md-4 mr-sm-4"
            :format="'YYYY-MM-DD'"
          ></date-picker>
        </div>
        <div class="calendar_search_block">
          <div class="label">Дата создания до</div>
          <date-picker
            v-model="created_before"
            :lang="lang"
            type="date"
            valueType="format"
            :format="'YYYY-MM-DD'"
          ></date-picker>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="search_calendar d-flex align-content-start flex-wrap">
        <div class="calendar_search_block">
          <div class="label">Срок действия от</div>
          <date-picker
            v-model="expires_from"
            :lang="lang"
            type="date"
            valueType="format"
            :format="'YYYY-MM-DD'"
            class="mr-md-4 mr-sm-4"
          ></date-picker>
        </div>
        <div class="calendar_search_block">
          <div class="label">Срок действия до</div>
          <date-picker
            v-model="expires_before"
            :lang="lang"
            type="date"
            valueType="format"
            :format="'YYYY-MM-DD'"
          ></date-picker>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="search_calendar d-flex align-content-start flex-wrap">
        <div class="calendar_search_block">
          <div class="label">Дата активации от</div>
          <date-picker
            v-model="activated_from"
            :lang="lang"
            type="date"
            valueType="format"
            :format="'YYYY-MM-DD'"
            class="mr-md-4 mr-sm-4"
          ></date-picker>
        </div>
        <div class="calendar_search_block">
          <div class="label">Дата активации до</div>
          <date-picker
            v-model="activated_before"
            :lang="lang"
            type="date"
            valueType="format"
            :format="'YYYY-MM-DD'"
          ></date-picker>
        </div>
      </div>
      <v-text-field label="Сумма" solo v-model="sum"></v-text-field>
      <v-text-field label="Комментарий бумажной выдачи" solo v-model="paper_comment_search"></v-text-field>
      <v-text-field solo label="Поиск по номеру" v-model="cert_numb"></v-text-field>
      <v-text-field solo label="Поиск по номеру документа yclients" v-model="yc_document"></v-text-field>
      <v-combobox
        v-model="project_ids"
        solo
        :items="active_project"
        :item-text="item => item.title"
        :item-value="item => item.project_pk"
        label="Выберите проекты для фильтрации"
        multiple
      >
      </v-combobox>
    </div>
    <v-btn color="default" small @click="clearFilter">Сбросить</v-btn>
    <v-btn color="primary" small class="ml-3" @click="applyFilter">Применить</v-btn>
    <v-divider class="mt-5 mb-5"></v-divider>
    <v-card>
      <v-data-table
        mobile-breakpoint="100"
        :headers="headers"
        :items="certs"
        item-key="pk"
        :loading="loading"
        class="elevation-1"
        loading-text="Загрузка данных"
        no-data-text="Данные не найдены"
      >
        <template #[`item.pk`]="{ item }">
          <div class="d-flex flex-column">
            <span @click="getCertByUuid(item.uuid)" class="d-block font-weight-semibold href">{{ item.pk }}</span>
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          <v-chip class="font-weight-medium" :color="statusColor[item.status]" small>
            {{ status[item.status] }}
          </v-chip>
        </template>

        <template #[`item.created_at`]="{ item }">
          <span v-if="item.created_at">{{ item.created_at | formatDate }}</span>
          <span v-else="">–</span>
        </template>

        <template #[`item.expires_at`]="{ item }">
          <span v-if="item.expires_at">{{ item.expires_at | formatDate }}</span>
          <span v-else="">–</span>
        </template>

        <template #[`item.activated_at`]="{ item }">
          <span v-if="item.activated_at">{{ item.activated_at | formatDate }}</span>
          <span v-else="">–</span>
        </template>

        <template #[`item.sum`]="{ item }">
          <span>{{ item.sum | format }} ₽</span>
        </template>

        <template #[`item.remain`]="{ item }">
          <span>{{ item.remain | format }} ₽</span>
        </template>

        <template slot="body.append">
          <tr>
            <th>Всего</th>
            <th>{{ certs.length }}</th>
            <th colspan="8">{{ sumField('sum') | format }} ₽</th>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      :scrim="false"
      v-if="active_sertificate"
      v-model="cert_dialog"
      width="100%"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click=";(cert_dialog = false), (active_sertificate = null)">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Сертификат №{{ active_sertificate.numb }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4">
              <v-list three-line subheader>
                <v-subheader>Данные сертификата</v-subheader>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Дата содания</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.created_at | formatDate }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Срок действия</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.expires_at | formatDate }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Дата активации</v-list-item-title>
                    <v-list-item-subtitle>
                      <template v-if="active_sertificate.activated_at">
                        {{ active_sertificate.activated_at | formatDate }}
                      </template>
                      <template v-else> - </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Создал</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.creator_name }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Документ выдачи/продажи</v-list-item-title>

                    <v-list-item-subtitle v-if="active_sertificate.ms_retail_demand">
                      <template v-if="active_sertificate.with_bill">
                        <a
                          target="_blank"
                          :href="
                            'https://online.moysklad.ru/app/#retaildemand/edit?id=' +
                            active_sertificate.ms_retail_demand
                          "
                          >Документ продажи</a
                        >
                      </template>
                      <template v-else>
                        <a
                          target="_blank"
                          :href="
                            'https://online.moysklad.ru/app/#demand/edit?id=' + active_sertificate.ms_retail_demand
                          "
                          >Документ отгрузки</a
                        >
                      </template>
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="active_sertificate.yc_document">
                      <a
                        target="_blank"
                        :href="
                          'https://yclients.com/storages/transactions/list/230691?document_number=' +
                          active_sertificate.yc_document
                        "
                        >Документ финансовой операции</a
                      >
                    </v-list-item-subtitle>

                    <v-list-item-subtitle v-if="active_sertificate.ms_incoming_payment">
                      <a
                        target="_blank"
                        :href="
                          'https://online.moysklad.ru/app/#paymentin/edit?id=' + active_sertificate.ms_incoming_payment
                        "
                        >Входящий платёж</a
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Статус</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip class="font-weight-medium" :color="statusColor[active_sertificate.status]">
                        {{ status[active_sertificate.status] }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-list-item v-if="active_sertificate.activated_at && active_sertificate.activation_document">
                <v-list-item-content>
                  <v-list-item-title>Документ активации (старый)</v-list-item-title>
                  <v-list-item-subtitle>
                    <a target="_blank" :href="getUUIDFromString(active_sertificate)"> Посмотреть документ</a>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Документы активации</v-list-item-title>
                  <v-list-item-subtitle>
                    <template v-if="active_sertificate.project_id == 2">
                      <v-list v-if="active_sertificate.write_of_documents">
                        <v-list-item v-for="(doc, index) in active_sertificate.write_of_documents" :key="doc.index">
                          <a :href="doc.document_link" target="_blank">
                            <v-icon>{{ icons.mdiFileDocument }}</v-icon>
                            {{ doc.date | formatDate }}
                          </a>
                          , {{ doc.write_off_sum | format }} ₽
                        </v-list-item>
                      </v-list>
                      <span v-else>–</span>
                    </template>
                    <template v-if="active_sertificate.project_id == 3 || active_sertificate.project_id == 4">
                      <a
                        v-if="active_sertificate.finances_transactions_document"
                        :href="
                          'https://yclients.com/finances/transactions/edit/230691/' +
                          active_sertificate.finances_transactions_document
                        "
                        target="_blank"
                      >
                        <v-icon>{{ icons.mdiFileDocument }}</v-icon>
                        ссылка на документ активации
                      </a>
                      <span v-else>-</span>
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-list three-line subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Номинал сертификата</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.sum | format }} ₽</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Остаток суммы</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.remain | format }} ₽</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Проект</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.project_title }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Название</v-list-item-title>
                    <v-list-item-subtitle v-if="active_sertificate.title">{{
                      active_sertificate.title
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>–</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Получатель</v-list-item-title>
                    <v-list-item-subtitle v-if="active_sertificate.recipient">{{
                      active_sertificate.recipient
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>–</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Секретное слово</v-list-item-title>
                    <v-list-item-subtitle>{{ active_sertificate.secret_word }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Скачать сертификат</v-list-item-title>
                    <v-list-item-subtitle>
                      <a target="_blank" :href="active_sertificate.link_to_download">
                        <v-btn class="ma-2" color="primary">
                          <v-icon>{{ icons.mdiFilePdf }}</v-icon>
                          Скачать PDF А4
                        </v-btn>
                      </a>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <a target="_blank" :href="active_sertificate.link_to_download + '?pdf_format=A6'">
                        <v-btn class="ma-2" color="primary">
                          <v-icon>{{ icons.mdiFilePdf }}</v-icon>
                          Скачать PDF А6
                        </v-btn>
                      </a>
                    </v-list-item-subtitle>

                    <template v-if="active_sertificate.group_uuid">
                      <v-list-item-title>Скачать архив сертификатов</v-list-item-title>
                      <v-list-item-subtitle>
                        <a target="_blank" :href="active_sertificate.link_to_download_group">
                          <v-btn class="ma-2" color="primary">
                            <v-icon>{{ icons.mdiFilePdf }}</v-icon>
                            Скачать архив PDF А4
                          </v-btn>
                        </a>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <a target="_blank" :href="active_sertificate.link_to_download_group + '?pdf_format=A6'">
                          <v-btn class="ma-2" color="primary">
                            <v-icon>{{ icons.mdiFilePdf }}</v-icon>
                            Скачать архив PDF А6
                          </v-btn>
                        </a>
                      </v-list-item-subtitle>
                    </template>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-list three-line subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Управление</v-list-item-title>
                    <v-btn
                      v-if="active_sertificate.project_id == 2"
                      v-show="active_sertificate.status != 'ACTIVATED' && active_sertificate.status != 'CANCELED'"
                      @click="use_cert_badmintonist = true"
                      class="ma-2"
                      color="success"
                    >
                      Использовать сертификат
                    </v-btn>
                    <v-list-item-subtitle>
                      <v-btn @click="archivedCert(active_sertificate)" class="ma-2" color="secondary">
                        <v-icon class="mr-2">{{ icons.mdiArchive }}</v-icon>
                        Архивировать
                      </v-btn>
                      <v-btn
                        v-show="active_sertificate.status != 'ACTIVATED' && active_sertificate.status != 'CANCELED'"
                        @click="cancelCert(active_sertificate)"
                        class="ma-2"
                        color="error"
                      >
                        <v-icon class="mr-2">{{ icons.mdiCancel }}</v-icon>
                        Аннулировать
                      </v-btn>
                    </v-list-item-subtitle>
                    <template v-if="!cert_history.paper_status">
                      <v-list-item-title class="mt-5 mb-5">Бумажная выдача</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-text-field outlined label="Кому выдано?" v-model="paper_comment"></v-text-field>
                        <v-btn @click="PaperComment(active_sertificate.uuid)" class="ma-2" color="primary">
                          Выдать
                        </v-btn>
                      </v-list-item-subtitle>
                    </template>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-list three-line subheader v-if="cert_history.paper_status">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>История бумажной выдачи</v-list-item-title>
                    <template>
                      <v-timeline align-top dense>
                        <v-timeline-item small>
                          <div>
                            <div>Статус выдачи: Выдан</div>
                            <div>Комментарий выдачи: {{ cert_history.paper_comment }}</div>
                            <div>Кто выдал: {{ cert_history.history_user }}</div>
                            <div>Дата изменения: {{ cert_history.updated_at }}</div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </template>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog small v-model="create_cert_dialog" persistent>
      <v-card ref="form">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click=";(create_cert_dialog = false), resetForm()">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Создать сертификат</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template>
          <v-card-text style="margin-top: 20px">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  ref="project_pk"
                  :items="active_project"
                  v-model="project_pk"
                  :item-text="item => item.title"
                  :item-value="item => item.project_pk"
                  label="Проект *"
                  hint="Выберите проект из списка"
                  persistent-hint
                  outlined
                  :rules="[() => !!project_pk || 'Обязательное поле для заполнения']"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </template>

        <template v-if="project_pk == 2">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-switch v-model="with_bill" hide-details inset label="Выпустить сертификат с чеком"></v-switch>
                <div class="label" style="margin-top: 10px">
                  Для получения сертификата с чеком необходимо создать продажу в системе "Мой Склад" перед выпуском
                  сертификата
                </div>
              </v-col>

              <template v-if="with_bill">
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="ms_retail_demand_link"
                    type="text"
                    :rules="[() => !!ms_retail_demand_link || 'Обязательное поле для заполнения']"
                    :error-messages="errorMessages"
                    v-model="ms_retail_demand_link"
                    required
                    label="Укажите ссылку на продажу в МС"
                    hint="Вставьте ссылку на документ продажи внутри системы Мой склад"
                    persistent-hint
                    outlined
                  ></v-text-field>

                  <v-btn class="mt-5" small color="primary" @click="getMsRetailDemandData(ms_retail_demand_link)">
                    проверить продажу
                  </v-btn>

                  <template v-if="check_ms_retail_demand">
                    <v-col cols="12" sm="12" class="mt-10">
                      <v-text-field
                        ref="cert_sum"
                        type="number"
                        :rules="[() => !!cert_sum || 'Обязательное поле для заполнения']"
                        :error-messages="errorMessages"
                        v-model="cert_sum"
                        required
                        label="Номинал сертификата *"
                        hint="Укажите номинал сертификата"
                        persistent-hint
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        ref="recipient"
                        v-model="recipient"
                        label="Получатель"
                        hint="На сертификате будет указан получатель"
                        persistent-hint
                        outlined
                        :error-messages="errorMessages"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        ref="title"
                        v-model="title"
                        label="Название услуги/Поздравление"
                        hint="На сертификате будет указана услуга или текст поздравления"
                        persistent-hint
                        outlined
                        :error-messages="errorMessages"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <div class="label">Срок действия</div>
                      <date-picker
                        ref="expires_at"
                        v-model="expires_at"
                        :lang="lang"
                        type="date"
                        valueType="format"
                        :format="'YYYY-MM-DD'"
                      ></date-picker>
                      <div class="label">
                        Поле можно оставить пустым, по умолчанию срок действия 12 месяцев с момента создания сертификата
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-textarea
                        ref="comment"
                        v-model="comment"
                        label="Комментарий"
                        hint="Комментарий сохранится в системе Мой склад"
                        persistent-hint
                        outlined
                        :error-messages="errorMessages"
                        :rules="[]"
                      ></v-textarea>
                    </v-col>
                  </template>
                </v-col>
              </template>

              <template v-else>
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="cert_sum"
                    type="number"
                    :rules="[() => !!cert_sum || 'Обязательное поле для заполнения']"
                    :error-messages="errorMessages"
                    v-model="cert_sum"
                    required
                    label="Номинал сертификата *"
                    hint="Укажите номинал сертификата"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="recipient"
                    v-model="recipient"
                    label="Получатель"
                    hint="На сертификате будет указан получатель"
                    persistent-hint
                    outlined
                    :error-messages="errorMessages"
                    :rules="[]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="title"
                    v-model="title"
                    label="Название услуги/Поздравление"
                    hint="На сертификате будет указана услуга или текст поздравления"
                    persistent-hint
                    outlined
                    :error-messages="errorMessages"
                    :rules="[]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-switch v-model="bulk_check" hide-details inset label="Массовый выпуск"></v-switch>
                  <div class="label" style="margin-top: 10px">
                    Вы можете создать до 50 сертификатов за один раз. Массовый выпуск доступен только для сертификатов
                    выпущенных без чека.
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="bulk_check">
                  <v-text-field
                    ref="cert_sum"
                    type="number"
                    :rules="[
                      () => bulk <= 50 || 'Максимальное значение 50',
                      () => bulk >= 5 || 'Минимальное значение 5',
                    ]"
                    :error-messages="errorMessages"
                    v-model="bulk"
                    required
                    label="Количество сертификатов"
                    hint="Укажите количество выпускаемых сертификтов за 1 раз"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <div class="label">Срок действия</div>
                  <date-picker
                    ref="expires_at"
                    v-model="expires_at"
                    :lang="lang"
                    type="date"
                    valueType="format"
                    :format="'YYYY-MM-DD'"
                  ></date-picker>
                  <div class="label">
                    Поле можно оставить пустым, по умолчанию срок действия 12 месяцев с момента создания сертификата
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-textarea
                    ref="comment"
                    v-model="comment"
                    label="Комментарий"
                    hint="Комментарий сохранится в системе Мой склад"
                    persistent-hint
                    outlined
                    :error-messages="errorMessages"
                    :rules="[]"
                  ></v-textarea>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </template>

        <template v-else-if="project_pk == 3 || project_pk == 4">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-switch v-model="with_bill" hide-details inset label="Выпустить сертификат с чеком"></v-switch>
                <div class="label" style="margin-top: 10px">
                  Для получения сертификата с чеком необходимо создать продажу сертификата в системе "Yclients" перед
                  выпуском сертификата
                </div>
              </v-col>

              <template v-if="with_bill">
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="yc_finance_number"
                    type="text"
                    :rules="[() => !!yc_finance_number || 'Обязательное поле для заполнения']"
                    :error-messages="errorMessages"
                    v-model="yc_finance_number"
                    required
                    label="Укажите номер документа финансовой операции продажи сертификата"
                    hint="Скопируйте и вставьте номер документа финансовой операции"
                    persistent-hint
                    outlined
                  ></v-text-field>

                  <v-btn class="mt-5" small color="primary" @click="checkCertOperation(yc_finance_number)">
                    проверить финансовую операцию
                  </v-btn>

                  <template v-if="check_yc_finance_operation">
                    <v-col cols="12" sm="12" class="mt-10">
                      <v-text-field
                        ref="cert_sum"
                        type="number"
                        :rules="[() => !!cert_sum || 'Обязательное поле для заполнения']"
                        :error-messages="errorMessages"
                        v-model="cert_sum"
                        required
                        label="Номинал сертификата *"
                        hint="Укажите номинал сертификата"
                        persistent-hint
                        outlined
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        ref="recipient"
                        v-model="recipient"
                        label="Получатель"
                        hint="На сертификате будет указан получатель"
                        persistent-hint
                        outlined
                        :error-messages="errorMessages"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                        ref="title"
                        v-model="title"
                        label="Название услуги/Поздравление"
                        hint="На сертификате будет указана услуга или текст поздравления"
                        persistent-hint
                        outlined
                        :error-messages="errorMessages"
                        :rules="[]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <div class="label">Срок действия</div>
                      <date-picker
                        ref="expires_at"
                        v-model="expires_at"
                        :lang="lang"
                        type="date"
                        valueType="format"
                        :format="'YYYY-MM-DD'"
                      ></date-picker>
                      <div class="label">
                        Поле можно оставить пустым, по умолчанию срок действия 12 месяцев с момента создания сертификата
                      </div>
                    </v-col>
                  </template>
                </v-col>
              </template>

              <template v-else>
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="cert_sum"
                    type="number"
                    v-model="cert_sum"
                    :rules="[() => !!cert_sum || 'Обязательное поле для заполнения']"
                    :error-messages="errorMessages"
                    required
                    label="Номинал сертификата *"
                    hint="Укажите номинал сертификата"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-select
                    ref="cash_box_id"
                    :items="cash_box_list"
                    v-model="select_cash_box_id"
                    :item-text="item => item.name"
                    :item-value="item => item.cash_box_id"
                    label="Касса *"
                    hint="Выберите кассу из списка"
                    persistent-hint
                    outlined
                    :rules="[() => !!project_pk || 'Обязательное поле для заполнения']"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="recipient"
                    v-model="recipient"
                    label="Получатель"
                    hint="На сертификате будет указан получатель"
                    persistent-hint
                    outlined
                    :error-messages="errorMessages"
                    :rules="[]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="title"
                    v-model="title"
                    label="Название услуги/Поздравление"
                    hint="На сертификате будет указана услуга или текст поздравления"
                    persistent-hint
                    outlined
                    :error-messages="errorMessages"
                    :rules="[]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-switch v-model="bulk_check" hide-details inset label="Массовый выпуск"></v-switch>
                  <div class="label" style="margin-top: 10px">
                    Вы можете создать до 50 сертификатов за один раз. Массовый выпуск доступен только для сертификатов
                    выпущенных без чека.
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="bulk_check">
                  <v-text-field
                    ref="cert_sum"
                    type="number"
                    :rules="[
                      () => bulk <= 50 || 'Максимальное значение 50',
                      () => bulk >= 5 || 'Минимальное значение 5',
                    ]"
                    :error-messages="errorMessages"
                    v-model="bulk"
                    required
                    label="Количество сертификатов"
                    hint="Укажите количество выпускаемых сертификтов за один раз"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <div class="label">Срок действия</div>
                  <date-picker
                    ref="expires_at"
                    v-model="expires_at"
                    :lang="lang"
                    type="date"
                    valueType="format"
                    :format="'YYYY-MM-DD'"
                  ></date-picker>
                  <div class="label">
                    Поле можно оставить пустым, по умолчанию срок действия 12 месяцев с момента создания сертификата
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </template>

        <v-divider></v-divider>
        <v-card-actions v-if="project_pk">
          <v-btn color="primary" @click="submit"> Создать </v-btn>
          <v-btn @click="resetForm(), (create_cert_dialog = false)" color="error"> Отменить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" v-model="use_cert_badmintonist" small :scrim="false">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click=";(use_cert_badmintonist = false), (ms_order_link = null)">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Применить сертификат</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-10">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                ref="ms_order_link"
                type="text"
                :rules="[() => !!ms_order_link || 'Обязательное поле для заполнения']"
                :error-messages="errorMessages"
                v-model="ms_order_link"
                required
                label="Укажите ссылку на заказ в МС"
                hint="Вставьте ссылку на документ заказа внутри системы Мой склад"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="ms_order_link">
          <v-btn color="primary" @click="activateСertBadmintonist(active_sertificate)"> Применить сертификат </v-btn>
          <v-btn @click=";(use_cert_badmintonist = false), (ms_order_link = null)" color="error"> Отменить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulk_cert_modal" small>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click=";(bulk_cert_modal = false), (bulk_data = [])">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title>Массовое создание сертификатов</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mt-10">
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="4">
              <h2>Сертификаты успешно созданы</h2>
              <p>
                Скачать архив сертификатов «формат A4»
                <a :href="bulk_data.link_to_download">{{ bulk_data.link_to_download }}</a> <br /><br />
                Скачать архив сертификатов «формат A6»
                <a :href="bulk_data.link_to_download">{{ bulk_data.link_to_download }}?pdf_format=A6</a>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :vertical="true">
      {{ snackbar_text }}
      <v-btn color="white" text @click="snackbar = false"> закрыть </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {
  mdiAccountOutline,
  mdiAccount,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiCurrencyRub,
  mdiBedKing,
  mdiRoomService,
  mdiCart,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiAccountGroup,
  mdiEye,
  mdiSwapHorizontal,
  mdiLeadPencil,
  mdiContactlessPaymentCircle,
  mdiShieldAccount,
  mdiSale,
  mdiClose,
  mdiFilePdf,
  mdiArchive,
  mdiCancel,
  mdiFileDocument,
} from '@mdi/js'

import { ref } from 'vue'
import Api from '@/api/api'
import { mapActions } from 'vuex'
import AddRoomModal from '@/views/events/AddRoomModal.vue'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { AddRoomModal, MyLoader, DatePicker },
  async created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {},
    )
  },
  setup() {
    const loading = false
    const loader = false
    const statusColor = {
      ACTIVATED: 'success',
      RELEASED: 'info',
      CANCELED: 'error',
    }

    const free_event_rooms = []

    // tabs
    return {
      headers: [
        { text: 'ID', value: 'pk', align: 'start', width: '50' },
        { text: 'Номер', value: 'numb', width: '100' },
        { text: 'Номинал', value: 'sum', width: '110' },
        { text: 'Остаток', value: 'remain', width: '100' },
        { text: 'Проект', value: 'project_title' },
        { text: 'Автор', value: 'creator_name' },
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Срок действия', value: 'expires_at' },
        { text: 'Дата Активации', value: 'activated_at' },
        { text: 'Статус', value: 'status', align: 'left' },
      ],
      headers_rooms: [{ text: 'Номер', value: 'name', align: 'start' }],
      status: {
        RELEASED: 'Выпущен',
        ACTIVATED: 'Активирован',
        CANCELED: 'Анулирован',
      },
      loader,
      statusColor,
      loading,
      free_event_rooms,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiCurrencyRub,
        mdiBedKing,
        mdiCart,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiAccount,
        mdiAccountGroup,
        mdiEye,
        mdiSwapHorizontal,
        mdiLeadPencil,
        mdiContactlessPaymentCircle,
        mdiShieldAccount,
        mdiSale,
        mdiClose,
        mdiFilePdf,
        mdiArchive,
        mdiCancel,
        mdiFileDocument,
      },
    }
  },
  data() {
    return {
      errorMessages: '',
      active_project: [],
      certs: [],
      filter_data: {},
      date_from: null,
      date_before: null,
      isFilterActive: false,

      // filter_data
      project_ids: [],
      sum: '',
      paper_comment_search: null,
      creator_pk: '',
      cert_numb: null,
      yc_document: null,
      created_from: null,
      created_from_menu: false,
      created_before: null,
      created_before_menu: false,

      expires_from: null,
      expires_from_menu: false,
      expires_before: null,
      expires_before_menu: false,

      activated_from: null,
      activated_from_menu: false,
      activated_before: null,
      activated_before_menu: false,

      snackbar: false,
      snackbar_text: null,

      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },

      active_sertificate: null,
      cert_history: [],
      paper_comment: null,

      cert_dialog: false,
      create_cert_dialog: false,

      // create cert
      cert_sum: null,
      bulk_check: null,
      bulk: null,
      bulk_data: [],
      project_pk: null,
      recipient: null,
      title: null,
      expires_at: null,
      ms_retail_demand_link: '',
      yc_finance_number: '',
      with_bill: false,
      comment: null,
      formHasErrors: false,

      check_ms_retail_demand: false,
      check_yc_finance_operation: false,
      use_cert_badmintonist: false,
      bulk_cert_modal: false,
      ms_order_link: null,
      select_cash_box_id: null,
      cash_box_list: [
        { name: 'Карта сбербанка', cash_box_id: 492489 },
        { name: 'ООО «Ромашка»', cash_box_id: 597805 },
      ],
    }
  },
  async mounted() {
    await this.getActiveProject()
    await this.getCerts()
  },
  watch: {
    with_bill() {
      if (!this.with_bill) {
        this.cert_sum = null
      }
    },
  },
  computed: {
    form() {
      return {
        cert_sum: this.cert_sum,
        project_pk: this.project_pk,
      }
    },
  },
  methods: {
    getActiveProject() {
      try {
        Api.getActiveProject().then(
          (event => {
            this.$set(this, 'active_project', event.data)
            // this.$set(this, 'loading', 'false')
          }).bind(this),
        )
      } catch (error) {}
    },
    getCerts() {
      this.loading = true
      try {
        Api.getCerts({
          filter_data: this.filter_data,
        }).then(
          (event => {
            this.$set(this, 'certs', event.data)
            this.$set(this, 'loading', false)
          }).bind(this),
        )
      } catch (error) {}
    },
    getCertByUuid(uuid) {
      this.loader = true
      try {
        Api.getCerts({
          filter_data: {
            cert_uuid: uuid,
          },
        }).then(
          (event => {
            this.$set(this, 'active_sertificate', event.data[0])
            this.getCertHistory(uuid)
            this.$set(this, 'loader', false)
            this.$set(this, 'cert_dialog', true)
          }).bind(this),
        )
      } catch (error) {}
    },
    getCertHistory(uuid) {
      this.loader = true
      try {
        Api.getCertHistory({
          uuid: uuid,
        }).then(
          (event => {
            this.cert_history = event.data
          }).bind(this),
        )
      } catch (error) {
        console.log('error')
      }
    },
    PaperComment(uuid) {
      if (confirm('Вы уверены что хотите выдать бумажный сертификат?')) {
        this.loader = true
        try {
          Api.paperComment({
            uuid: uuid,
            paper_comment: this.paper_comment,
          }).then(
            (event => {
              this.paper_comment = null
              this.getCertByUuid(uuid)
            }).bind(this),
          )
        } catch (error) {
          console.log('error')
        }
      }
    },
    applyFilter() {
      const filter = {}
      if (this.project_ids && this.project_ids.length > 0) {
        filter.projects_ids = this.project_ids.map(project => project.project_pk)
      }
      if (this.sum) {
        filter.cert_sum = parseInt(this.sum)
      }
      if (this.paper_comment_search) {
        filter.paper_comment_search = this.paper_comment_search
      }
      if (this.creator_pk) {
        filter.creator_pk = this.creator_pk
      }
      if (this.created_from && this.created_before) {
        filter.created_from = this.created_from
        filter.created_before = this.created_before
      }

      if (this.expires_from && this.expires_before) {
        filter.expires_from = this.expires_from
        filter.expires_before = this.expires_before
      }

      if (this.activated_from && this.activated_before) {
        filter.activated_from = this.activated_from
        filter.activated_before = this.activated_before
      }

      if (this.cert_numb) {
        filter.cert_numb = this.cert_numb
      }

      if (this.yc_document) {
        filter.yc_document = this.yc_document
      }

      try {
        this.loading = true
        Api.getCerts({
          filter_data: filter,
        }).then(
          (event => {
            this.$set(this, 'certs', event.data)
            this.$set(this, 'loading', false)
          }).bind(this),
        )
      } catch (error) {}
    },
    clearFilter() {
      this.project_ids = []
      this.sum = ''
      this.created_from = null
      this.created_before = null
      this.expires_from = null
      this.expires_before = null
      this.activated_from = null
      this.activated_before = null
      this.cert_numb = null
      this.yc_document = null
      this.paper_comment_search = null
    },
    sumField(key) {
      // sum data in give key (property)
      return this.certs.reduce((a, b) => a + (b[key] || 0), 0)
    },
    getUUIDFromString(active_sertificate) {
      if (active_sertificate) {
        if (active_sertificate.project_id == 2) {
          const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
          const match = active_sertificate.activation_document.match(uuidRegex)
          if (match) {
            let st = 'https://online.moysklad.ru/app/#cashin/edit?id=' + match[0]
            return st
          }
          return null
        } else if (active_sertificate.project_id == 3 || active_sertificate.project_id == 4) {
          const numb = /(\d+)/
          const match = active_sertificate.activation_document.match(numb)
          if (match) {
            let st = 'https://yclients.com/finances/transactions/edit/230691/' + match[0]
            return st
          }
          return null
        }
      }
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false

      this.cert_sum = null
      this.bulk = null
      this.bulk_check = null
      this.project_pk = null
      this.ms_retail_demand_link = null
      this.yc_finance_number = null
      this.with_bill = false
      this.recipient = null
      this.title = null
      this.expires_at = null
      this.comment = null
      this.check_ms_retail_demand = false
      this.check_yc_finance_operation = false
      this.select_cash_box_id = null

      Object.keys(this.form).forEach(f => {
        if (this.$refs[f]) {
          this.$refs[f].reset()
        }
      })
    },
    createCert(create_data) {
      let data = create_data
      this.loader = true
      try {
        Api.createCert(data).then(
          (event => {
            this.$set(this, 'active_sertificate', event.data)
            this.$set(this, 'loader', false)
            this.$set(this, 'cert_dialog', true)
            this.$set(this, 'create_cert_dialog', false)
            this.resetForm()
            this.getCerts()
          }).bind(this),
        )
      } catch (error) {}
    },
    createCertBadmintonist(create_data) {
      let data = create_data
      this.loader = true
      try {
        if (this.with_bill) {
          Api.createBadmintonistWithBill(data).then(
            (event => {
              this.$set(this, 'active_sertificate', event.data)
              this.$set(this, 'loader', false)
              this.$set(this, 'cert_dialog', true)
              this.$set(this, 'create_cert_dialog', false)
              this.resetForm()
              this.getCerts()
            }).bind(this),
          )
        } else {
          Api.createBadmintonistWithoutBill(data).then(
            (event => {
              if (event.data.group_uuid) {
                this.$set(this, 'bulk_data', event.data)
                this.$set(this, 'loader', false)
                this.$set(this, 'create_cert_dialog', false)
                this.$set(this, 'bulk_cert_modal', true)
              } else {
                this.$set(this, 'active_sertificate', event.data)
                this.$set(this, 'loader', false)
                this.$set(this, 'cert_dialog', true)
                this.$set(this, 'create_cert_dialog', false)
                this.resetForm()
                this.getCerts()
              }
            }).bind(this),
          )
        }
      } catch (error) {}
    },
    createSpaceXbadm(create_data) {
      let data = create_data
      this.loader = true

      if (this.with_bill) {
        Api.createSpaceXbadmBill(data)
          .then(
            (event => {
              this.$set(this, 'active_sertificate', event.data)
              this.$set(this, 'loader', false)
              this.$set(this, 'cert_dialog', true)
              this.$set(this, 'create_cert_dialog', false)
              this.resetForm()
              this.getCerts()
            }).bind(this),
          )
          .catch(error => {
            alert(error.response.data.data.error)
            this.$set(this, 'loader', false)
          })
      } else {
        Api.createSpaceXbadmNoBill(data)
          .then(
            (event => {
              if (event.data.group_uuid) {
                this.$set(this, 'bulk_data', event.data)
                this.$set(this, 'loader', false)
                this.$set(this, 'create_cert_dialog', false)
                this.$set(this, 'bulk_cert_modal', true)
              } else {
                this.$set(this, 'active_sertificate', event.data)
                this.$set(this, 'loader', false)
                this.$set(this, 'cert_dialog', true)
                this.$set(this, 'create_cert_dialog', false)
                this.resetForm()
                this.getCerts()
              }
            }).bind(this),
          )
          .catch(error => {
            alert(error.response.data.data.error)
            this.$set(this, 'loader', false)
          })
      }
    },
    archivedCert(cert) {
      if (confirm('Вы уверены что хотите добавить сертификат в архив?')) {
        this.loader = true
        try {
          Api.archivedCert({ cert_id: cert.pk }).then(
            (event => {
              this.$set(this, 'loader', false)
              this.$set(this, 'cert_dialog', false)
              this.$set(this, 'active_sertificate', null)
              this.getCerts()
            }).bind(this),
          )
        } catch (error) {}
      }
    },
    cancelCert(cert) {
      if (confirm('Вы уверены что хотите аннулировать сертификат?')) {
        this.loader = true
        try {
          Api.cancelCert({ cert_uuid: cert.uuid }).then(
            (event => {
              this.$set(this, 'loader', false)
              this.getCertByUuid(cert.uuid)
            }).bind(this),
          )
        } catch (error) {}
      }
    },
    getMsRetailDemandData(link) {
      if (!this.ms_retail_demand_link) {
        this.$refs['ms_retail_demand_link'].validate(true)
      } else {
        Api.msRetailDemandData({ retail_demand_link: link })
          .then(
            (event => {
              if (event.data.retail_demand_sum > 0) {
                this.check_ms_retail_demand = true
                this.snackbar_text =
                  'Контрагент:' + event.data.counterparty_name + ', Сумма заказа:' + event.data.retail_demand_sum
                this.snackbar = true
                this.cert_sum = event.data.retail_demand_sum
                console.log(event)
              } else {
                this.check_ms_retail_demand = false
              }
            }).bind(this),
          )
          .catch(error => {
            this.check_ms_retail_demand = false
            this.snackbar_text = 'Не удалось получить данные продажи'
            this.snackbar = true
          })
      }
    },
    checkCertOperation(number) {
      this.loader = true
      if (!this.yc_finance_number) {
        this.$refs['yc_finance_number'].validate(true)
      } else {
        Api.checkCertOperation({ document_number: number })
          .then(
            (event => {
              if (event.data.cost > 0) {
                this.check_yc_finance_operation = true
                this.snackbar_text = 'Клиент:' + event.data.client.name + ', Сумма операции:' + event.data.cost
                this.snackbar = true
                this.cert_sum = event.data.cost
              } else {
                this.check_yc_finance_operation = false
              }
              this.$set(this, 'loader', false)
            }).bind(this),
          )
          .catch(error => {
            this.check_yc_finance_operation = false
            this.snackbar_text = error.response.data.data.error
            this.snackbar = true
            this.$set(this, 'loader', false)
          })
      }
    },
    activateСertBadmintonist(cert) {
      if (!this.ms_order_link) {
        this.$refs['ms_order_link'].validate(true)
      } else {
        Api.activateСertBadmintonist({
          cert_numb: cert.numb,
          secret_word: cert.secret_word,
          customer_order_link: this.ms_order_link,
        })
          .then(
            (event => {
              if (event.data.activation_result) {
                this.use_cert_badmintonist = false
                this.ms_order_link = null
                this.getCertByUuid(cert.uuid)
              }
            }).bind(this),
          )
          .catch(error => {
            this.snackbar_text = 'Не удалось найти заказ'
            this.snackbar = true
            console.log(error.data)
          })
      }
    },
    submit() {
      this.formHasErrors = false

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) {
          this.formHasErrors = true
        }
        this.$refs[f].validate(true)
      })

      if (this.with_bill) {
        if (this.project_pk == 2) {
          if (!this.ms_retail_demand_link) {
            this.formHasErrors = true
            this.$refs['ms_retail_demand_link'].validate(true)
          }
        }

        if (this.project_pk == 3 || this.project_pk == 4) {
          if (!this.yc_finance_number) {
            this.formHasErrors = true
            this.$refs['yc_finance_number'].validate(true)
          }
        }
      }

      if (this.formHasErrors) {
        return false
      } else {
        const create_data = {}
        create_data.cert_sum = parseInt(this.cert_sum)

        if (this.recipient) {
          create_data.recipient = this.recipient
        }
        if (this.title) {
          create_data.title = this.title
        }
        if (this.expires_at) {
          create_data.expires_at = this.expires_at
        }
        if (this.project_pk == 2) {
          create_data.project_id = this.project_pk

          if (this.ms_retail_demand_link) {
            create_data.ms_retail_demand_link = this.ms_retail_demand_link
          }
        }

        if (this.project_pk == 3 || this.project_pk == 4) {
          if (this.select_cash_box_id) {
            create_data.cash_box_id = this.select_cash_box_id
          }
          if (this.project_pk == 3) {
            create_data.site_url = 'cert.xbadm.ru'
          }

          if (this.project_pk == 4) {
            create_data.site_url = 'cert.racketspace.ru'
          }

          if (this.yc_finance_number) {
            create_data.document_number = this.yc_finance_number
          }

          create_data.yc_client_id = this.$store.state.auth.user.yc_id
        }

        if (this.comment) {
          create_data.comment = this.comment
        }

        if (this.bulk > 0) {
          create_data.bulk = parseInt(this.bulk)
        }

        if (this.project_pk == 2) {
          this.createCertBadmintonist(create_data)
        } else if (this.project_pk == 3 || this.project_pk == 4) {
          this.createSpaceXbadm(create_data)
        } else {
          this.createCert(create_data)
        }
      }
    },
    msDemandForCertTemp(uuid) {
      Api.msDemandForCertTemp({
        cert_uuid: uuid,
      })
        .then(
          (event => {
            console.log(event.data)
          }).bind(this),
        )
        .catch(error => {
          this.snackbar_text = 'ОШИБКА'
          this.snackbar = true
        })
    },
  },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate: function (value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
    },
  },
}
</script>
<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.search_calendar {
  margin-bottom: 20px;
  margin-top: 20px;
}

.mx-input {
  height: 48px;
}
</style>
