<template>
  <div>
    <v-row>
      <v-col lg="4" sm="6" cols="12" class="align-self-start" :key="event.id" v-for="event in events">
        <v-card>
          <v-img :src="'https://lk.xbadm.ru/' + event.image[0]"></v-img>
          <v-card-title>{{ event.title }}</v-card-title>
          <v-card-text>{{ event.date }}</v-card-text>
          <v-card-actions class="primary pa-0">
            <v-btn @click="setEventData(event)" :to="'/events/' + event.id" color="primary" block dark large>
              Подробнее
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    events: { type: Array, required: true },
  },
  setup(props) {
    const isCardDetailsVisible = false
  },
  methods: {
    ...mapActions(['addEventnData']),
    setEventData(event) {
      this.addEventnData(event)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
