import axios from 'axios'
import store from '../store'

const jwtInterceptor = axios

jwtInterceptor.interceptors.request.use(config => {
  const authData = store.getters['token']

  if (authData == null) {
    return config
  }

  config.headers.common['Authorization'] = `Bearer ${authData}`
  return config
})

jwtInterceptor.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (store.getters['token'] && error.response.status === 401) {
      await store.dispatch('clearToken')

      const refresh_token = store.getters['refresh']
      const payload = {
        refresh: refresh_token,
      }

      var response = await axios.post('/account/refresh/', payload)

      if (response.status === 401) {
        console.log(error)
        return Promise.reject(error)
      } else {
        await store.dispatch('saveTokensToStorage', response.data)
        error.config.headers['Authorization'] = `Bearer ${response.data.access}`

        return axios(error.config)
      }
    } else {
      return Promise.reject(error)
    }
  },
)

export default jwtInterceptor
