import axios from "axios"
import authHeader from '@/api/auth-header';
import jwtInterceptor from '@/api/jwtInterceptor';
import router from "@/router";


const state = {
    token: null,
    refresh: null,
    isAuthenticated: false,
    isDrawerOpen: true,
    is_admin: false,
    dark: false,
    user: null
};

const getters = {
    token: (state) => state.token,
    refresh: (state) => state.refresh,
    isAuthenticated: (state) => state.isAuthenticated,
    isDrawerOpen: (state) => state.isDrawerOpen,
    isAdmin: (state) => state.is_admin,
    dark: (state) => state.dark,
    user: (state) => state.user,
};

const actions = {

    async getAccount({ commit }) {
        try {
            const response = await jwtInterceptor.get("account/");
            await commit("setUserData", response.data);
        } catch (e) {
            await commit("unSetUser");
        }

    },

    async LogIn({ commit }, user) {
        const response = await axios.post("account/login/", user);
        await commit("setUser", response.data);
    },

    async saveTokensToStorage({ commit }, data) {
        await commit("updateToken", data);
    },

    async clearTokensToStorage({ commit }) {
        await commit("unSetUser");
    },

    async clearToken({ commit }) {
        await commit("unSetToken");
    },

    async logout({ commit }) {
        let user = null;
        await commit("unSetUser", user);
    },

    get_permission() {

    }
};

const mutations = {
    setUser(state, data) {
        localStorage.setItem('accessToken', data.token.access);
        state.token = data.token.access;
        state.refresh = data.token.refresh;
        if (state.token) {
            state.isAuthenticated = true;
        } else {
            state.isAuthenticated = false;
        }
    },

    setUserData(state, data) {
        state.user = data;
    },

    unSetUser(state) {
        state.token = null;
        state.refresh = null
        localStorage.removeItem('accessToken');
        state.isAuthenticated = false;
        state.user = null;
    },

    unSetToken(state) {
        state.token = null;
    },

    updateToken(state, response) {
        localStorage.setItem('accessToken', response.access);
        if (response.access) {
            state.token = response.access;
            state.refresh = response.refresh;
        } else {
            this.unSetUser(state)
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};