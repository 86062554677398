<template>
  <div>
    <p class="text-2xl mb-6">
      Операции с бонусами
    </p>

    <div>
      <ul class="btn_list">
        <li>
          <v-btn @click="new_bonustransaction_operation_popup = true" color="primary">
            Создать операцию
          </v-btn>
        </li>
      </ul>
    </div>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Поиск транзакции"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :items-per-page="50"
        :headers="headers"
        :items="bonus_transaction"
        :search="search"
        class="elevation-1"
        :loading="loading"
        loading-text="Загрузка данных"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="d-flex flex-column">
            <router-link :to="'/clients/' + item.id">
              <span class="d-block font-weight-semibold">{{ item.id }}</span>
            </router-link>
          </div>
        </template>
        <template v-slot:[`item.event`]="{ item }">
          <div v-if="item.event_pk">
            {{ item.event }}
          </div>
          <div v-else>
            {{ item.transaction_title }}
          </div>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <div v-if="item.type == 'Начисление'">+ {{ item.amount }}</div>
          <div v-else>- {{ item.amount }}</div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="new_bonustransaction_operation_popup" transition="" max-width="600" persistent>
      <v-card>
        <v-toolbar color="primary" dark>
          Создать бонусную операцию
        </v-toolbar>
        <v-card-subtitle style="margin-top: 16px;">Начисления/списания выполняются автоматически</v-card-subtitle>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <autocomplete @updateParent="updateSelected" style="margin-bottom: 20px;"></autocomplete>
            <v-select
              v-model="select_bonustransaction_type"
              :items="bonustransaction_type"
              item-text="name"
              item-value="value"
              :rules="[v => !!v || 'Обязательное поле']"
              label="Тип операции"
              required
            ></v-select>
            <v-text-field v-model="bonustransaction_name" :rules="nameRules" label="Название" required></v-text-field>
            <v-text-field v-model="bonustransaction_amount" :rules="amountRules" label="Сумма" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="createBonusTransaction()">
            Создать операцию
          </v-btn>
          <v-btn
            color="error"
            @click="
              new_bonustransaction_operation_popup = false
              reset()
            "
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import Autocomplete from '@/components/Autocomplete.vue'

export default {
  components: {
    Autocomplete,
  },
  setup() {
    const bonus_transaction = []
    const loading = true
    const new_bonustransaction_operation_popup = false
    const bonustransaction_name = ''
    const bonustransaction_amount = ''
    const selected_user = ''

    return {
      bonus_transaction,
      search: '',
      headers: [
        { text: 'id', align: 'start', value: 'transaction_id', sortable: false },
        { text: 'Дата', value: 'date', sortable: false },
        { text: 'Тип операции', value: 'type', sortable: false },
        { text: 'Сумма', value: 'amount', sortable: false },
        { text: 'Событие/Название', value: 'event', sortable: false },
        { text: 'Клиент', value: 'user', sortable: false },
      ],
      loading,
      valid: '',
      new_bonustransaction_operation_popup,
      select_bonustransaction_type: null,
      bonustransaction_type: [
        { name: 'Начисление', value: 'UP' },
        { name: 'Списание', value: 'DWN' },
      ],
      bonustransaction_name,
      nameRules: [v => !!v || 'Обязательное поле'],
      bonustransaction_amount,
      amountRules: [v => !!v || 'Обязательное поле', v => (v && v > 0) || 'Сумма должна быть больше 0'],
      selected_user,
    }
  },
  mounted() {
    this.get_bonustransaction()
  },
  methods: {
    async get_bonustransaction() {
      this.loading = true
      try {
        Api.getBonusTransaction().then(
          (event => {
            this.$set(this, 'bonus_transaction', event.data)
            this.$set(this, 'loading', 'false')
          }).bind(this),
        )
      } catch {}
    },
    validate() {
      this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    updateSelected(data) {
      console.log(data)
      this.selected_user = data
    },
    async createBonusTransaction() {
      if (this.$refs.form.validate()) {
        if (this.select_bonustransaction_type == 'UP') {
          Api.createBonusTransaction({
            user_id: this.selected_user.id,
            title: this.bonustransaction_name,
            amount: parseInt(this.bonustransaction_amount, 10),
            type_transaction: this.select_bonustransaction_type,
          }).then(
            (event => {
              this.get_bonustransaction()
              this.reset()
              this.new_bonustransaction_operation_popup = false
              console.log(event)
            }).bind(this),
          )
        } else {
          Api.writeOffBonusesWithComment({
            user_pk: this.selected_user.id,
            comment: this.bonustransaction_name,
            bonuses_amount: parseInt(this.bonustransaction_amount, 10),
          }).then(
            (event => {
              this.get_bonustransaction()
              this.reset()
              this.new_bonustransaction_operation_popup = false
              console.log(event)
            }).bind(this),
          )
        }
      }
    },
  },
}
</script>

<style scoped>
.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
