<template>
  <div v-if="events_list">
    <p class="text-2xl mb-6">Список мест</p>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  components: {},
  setup() {
    const events_list = null

    return {
      events_list,
    }
  },
  mounted() {
    // this.get_all_events()
  },
  methods: {
    async get_all_events() {
      try {
        Api.getEvents().then(
          (event => {
            this.$set(this, 'events_list', event)
          }).bind(this),
        )
      } catch {}
    },
  },
}
</script>
